<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统设置</el-breadcrumb-item>
            <el-breadcrumb-item>运维管理</el-breadcrumb-item>
        </el-breadcrumb>
         <el-card shadow="hover" class="box-card" align="middle">
             <div class="head">
        <i
          class="el-icon-s-tools"
          style="font-size: 18px;"
        ></i>
        <span class="text">设置选项</span>
      </div>
      </br></br></br>
             <el-form  :model="operationSetting" label-width="150px" :rules="rules">
        <el-form-item label="显示CPU情况：">
            <el-switch v-model="operationSetting.cpu"></el-switch>
        </el-form-item>
  
            <!-- <el-form-item label="开放高级搜索：" size="mini" >
  			<el-radio-group v-model="dataFileSetting.searchedEnabled">
  				<el-radio :label="true">是</el-radio>
  				<el-radio :label="false">否</el-radio>
  			</el-radio-group>
  		</el-form-item> -->
           <el-form-item label="显示内存情况：">
            <el-switch v-model="operationSetting.memory"></el-switch>
        </el-form-item>
        <el-form-item label="显示存储情况：">
            <el-switch v-model="operationSetting.storage"></el-switch>
        </el-form-item>
        <el-form-item label="大数据服务显示：">
           <div  class="scroll" style="height:200px; width:200px;">
    <el-scrollbar style="height:100%">
      <el-tree
					show-checkbox
					:data="treedata"
					:props="treeProps"
					default-expand-all
					node-key="id"
					:default-checked-keys="watchdefKeys"
					ref="watchtree">
					</el-tree>
    </el-scrollbar>
</div>
        </el-form-item>
  
          
             <el-form-item>
                <el-button class="finish" type="primary" @click="onSubmit">完成</el-button>
            </el-form-item>
            </el-form>
               
         </el-card>

         <!-- <el-scrollbar style="height:100%">
	<div style="width:700px;height:700px;border:solid;">
		<ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto">
			<li v-for="i in count" class="infinite-list-item">{{ i }}</li>
		</ul>
	</div>
</el-scrollbar> -->





    </div>
</template>


<script>
	// 验证规则，需为数字值
	const checkTime = (rule, value, callback) => {
		if (!value) {
			return callback(new Error('输入不能为空'));
		}
		console.log("checkTime", value);
		let intValue = parseInt(value);
		if (!Number.isInteger(intValue)) {
			return callback(new Error('请输入数字值'));
		}
		callback();
	};
	export default {
		name: "set_operationCenter",
		data() {
			return {
       watchdefKeys:[],
				count: 0,
				operationSetting: {
          name:'运维管理',
					cpu: true,
					memory: true,
					storage: true,  
          bigdataservice:'1'

				},
        treedata:[],
         data: [{ parentId: 0, id: 1, value: 'hive' },
         { parentId: 0, id: 2, value: 'hbase' }, 
         { parentId: 0, id: 3, value: 'spark' }, 
         { parentId: 0, id: 4, value: 'flink' },
        { parentId: 0, id: 5, value: 'zookeeper' },
        { parentId: 0, id: 6, value: 'kafka' },
        { parentId: 0, id: 7, value: 'mysql' },
        { parentId: 0, id: 8, value: 'influxdb' }
          ],
        treeProps:{
            children: 'children',
            label: 'value'
        },
				rules: {
					pageCount: {
						validator: checkTime,
						trigger: 'blur'
					}
				}
			}
		},
		methods:{
      // 完成按钮
       onSubmit() {
      this.$confirm("此操作将修改设置信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {

          this.putRequest(
            "/systemSetting/updateSettingInfoByName",
            this.operationSetting
          ).then((resp) => {
            // this.postRequest('/systemSetting/insertSettingInfo',this.dataFileSetting).then(resp => {
            if (resp) {
              console.log(resp);
              console.log("修改成功!"); 
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },
    initData(){
            
                this.getRequest('/systemSetting/getSettingInfoByName/?name=' + this.operationSetting.name).then(resp=>{
                    if (resp){
                  
                        this.operationSetting.cpu = resp.data.cpu;
                        this.operationSetting.memory = resp.data.memory;
                        this.operationSetting.storage = resp.data.storage;
                      
                    }
                })
            }
  
		
		},
     mounted() {
		this.treedata=this.treeDataTranslate(this.data);
    	this.initData();
	}
	}
</script>


<style scoped>
.box-card {
    width: 800px;
  }
  .el-card:hover{
    margin-top: -3px;
  }
  .finish{
      margin-top: 30px;
      margin-left:330px;
  }
  .el-scrollbar__wrap {
    overflow-x: hidden;
}
 .contant_box{
    position: absolute;
    top:40px;
    bottom:0;
    width: 99%;
}
 
  .tabox {
    width:100%;
    height:100%;
    overflow: hidden;
  }
  .tabox>div {
    height: 100% !important;

  }

</style>
